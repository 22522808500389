<script>
import store from '@/store/store'
import authType from '@/store/type/authType'
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import routeType from '@/router/routeType'

export default {
    name: 'OrderConfirm',
    data() {
        return {
            success: null,
            already: null,
            error: null,
        }
    },
    computed: {
        toLogin() {
            return { name: routeType.LOGIN }
        },
    },
    beforeRouteEnter(to, from, next) {
        RestApiCollection.get(RestApiType.ORDERS_PUBLIC)
            .approve(to.params.id)
            .then((res) => {
                if (store.getters[authType.getters.IS_LOGGED_IN])
                    next({ name: routeType.ORDER_LIST })
                else
                    next((vm) => {
                        vm.$set(vm, 'success', res.success)
                        vm.$set(vm, 'already', res.already_approved)
                    })
            })
            .catch((e) => {
                if (store.getters[authType.getters.IS_LOGGED_IN])
                    next({ name: routeType.ORDER_LIST })
                else
                    next((vm) => {
                        vm.$set(vm, 'success', false)
                        vm.$set(vm, 'error', e.response?.errors?.message)
                    })
            })
    },
}
</script>

<template lang="pug">
.d-flex.align-center.justify-center.flex-column.order-confirm
    h2.text-center(:class="{'error--text': error, 'success--text': success, 'warning--text': already}")
        template(v-if="already") {{$t('orders.order_already_confirmed')}}
        template(v-else-if="success") {{$t('orders.order_confirmed')}}
        template(v-else-if="error") {{$t('orders.order_confirmation_error')}}
        .w-100
        v-btn(:to="toLogin" color="info" dense small) {{ $t('auth.go_to_login') }}
    p.mt-4(v-if="!success && error") {{error}}
</template>

<style scoped lang="scss">
.order-confirm {
    height: calc(100vh - 64px);
}
</style>
